import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';  // Adjust the path if needed
import App from './App';  // Ensure it imports from App.js
import reportWebVitals from './reportWebVitals';  // If this file is present

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
